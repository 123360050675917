<template>
    <v-container style="margin: 0; padding: 0">
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline"><span class="primary--text">PAINEL JÚRI > </span> Principal</v-subheader>
                  <p-breadcrumbs page="profile.jury-panel.main.home" />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                    v-if="items.length == 0"
                >
                    Lamentamos, mas ainda não tem nenhum projecto registado.
                </v-alert>
            </v-col>
        </v-row>
        
        <div class="body" v-for="(item, i) in items" :key="item.project.id">
            <v-divider v-if="(i+1)%2 === 0" class="ml-2x mr-2x" />
            <Project :item="item" />
        </div>

        <!--v-container v-if="hasMore">
            <v-row align="center" justify="center">
                <v-btn 
                :loading="loading" 
                :disabled="loading"
                @click="fetchMore">Carregue Mais</v-btn>
            </v-row>
        </v-container-->
    </v-container>
</template>
<script>
import { HOME } from '@/routes';
import Project from '@/components/author/jury-panel/main/Project';
import { mapState, mapActions } from 'vuex';
export default {
    components: {
        Project,
    },

    data: () => ({
        
        
    }),

    created: function () {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        //this.fetchItems(this.filter);
    },

    computed: {
        ...mapState('juryPanel/main', ['loading',  'hasMore', 'items']),
        filter() {
            return { 
                limit: 20,
                id: this.$user.info.id,
            }
        }
    },

    methods: {
        ...mapActions('juryPanel/main', {
            loadMore: 'loadMore',
            list: 'list'
        }),

        fetchMore() {
            this.loadMore(this.filter);
        },
    },

    mounted() {
        if (this.$route.params.festival)
            this.list({ festival: this.$route.params.festival });
    }
}
</script>