<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-alert v-for="(msg, id) in messages" :key="id"
                    :type="msg.success ? 'success' : 'error'"
                    >{{msg.message}}</v-alert>
            </v-col>

            <v-col cols="12" sm="12" lg="3" >
                <div class="p-1x" 
                    style="width: 100%; max-width: 100%; height: 100%; max-height: 100%;">
                    <div class="justify-center align-center text-center" >
                            <v-img :src="item.project.src" />
                        
                        <v-card-title class="justify-center align-center">
                            <span> {{ item.project.title}} </span>
                        </v-card-title>

                        <v-card-subtitle class="text-center">
                            <v-btn :to="item.project.$route.view" text>
                                <img
                                    height="24"
                                    width="24"
                                    src="@/assets/i-play.png"
                                    />
                                    <span class="ml-1">Visualizar Obra</span>
                            </v-btn>
                        </v-card-subtitle>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" sm="12" lg="9">
                <div class="p-1x"
                    style="width: 100%; max-width: 100%;height: 100%; max-height: 100%;">
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="primary--text">Sinopse</p>
                            <div class="mt-1x" v-html="item.project.sinopse"></div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="primary--text">Duração</p>
                            <div class="mt-1x" v-html="item.project.duration"></div>

                            <p class="primary--text mt-2x">Créditos</p>
                            <div class="mt-1x" v-for="(credit, i) in item.project.credits" :key="i">
                                - {{ credit }}
                            </div>
                        </v-col>
                    </v-row>
                    
                </div>
            </v-col>
        </v-row>
        
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-row align="center" justify="center">
                <v-col cols="12" md="3">
                    <v-row align="center" justify="center">
                        <v-btn
                            color="success"
                            @click="submit" 
                            v-if="!item.review.id"
                            :loading="submiting" :disabled="submiting"
                            >Avaliar</v-btn>
                        <v-btn color="success" class="elevation-0" @click="submit" outlined v-else>Avaliado</v-btn>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2" v-for="a in ratings"
                                :key="a" class="ml-2">
                            <v-checkbox
                                :readonly="item.status === 'APPROVED' || submiting"
                                color="primary"
                                v-model="item.review.review"
                                :label="`${a}`"
                                :value="a"
                                :rules="ratingRules"
                                :name="`aval${a}`"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" class="ml-4">
                    <v-row class="mb-4"><span class="primary--text">Observações</span></v-row>
                    <v-row>
                        <v-textarea
                            outlined :readonly="item.status === 'APPROVED' || submiting"
                            v-model="item.review.observation"
                        ></v-textarea>
                    </v-row>
                    <v-row>
                        <v-select
                            outlined
                            :readonly="item.status === 'APPROVED' || submiting"
                            :items="status"
                            item-text="name"
                            item-value="id"
                            :rules="statusRule"
                            v-model="item.status"
                            label="Aceitar subscrição"
                        ></v-select>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        item: {
            type: Object
        },
    },
    data: () => ({
        valid: true,
        ratings: [
            1, 2, 3, 4, 5, 
            6, 7, 8, 9, 10
        ],
        ratingRules: [
            v => !!v || 'Por favor, dá uma pontuação a subscrição.',
        ],
        statusRule: [
            v => !!v || 'Por favor, aceita ou rejeita a subscrição.',
        ],
        messages: [],
        status: [
            { id: 'UNDECIDED',         nme: 'Indeciso'},
            { id: 'IN_CONSIDERATION',  name: 'Em consideração'},
            { id: 'SELECTED',          name: 'Selecionado'},
            { id: 'INCOMPLETE',        name: 'Incompleto'},
            { id: 'SEMI_FINALIST',     name: 'Semi-Finalista'},
            { id: 'DISQUALIFIED',      name: 'Desqualificado'},
            { id: 'FINALIST',          name: 'Finalista'},
            { id: 'REMOVED',           name: 'Removido'},
        ],
        submiting: false,
    }),

    computed:  {
        user() {
            if (this.$user.isAuthenticated)
                return this.$user.info;
            return null;
        },
    },
    methods: {
        ...mapActions('juryPanel/main', {
            submitReview: 'submitReview'
        }),

        submit() {
            if (this.$refs.form.validate()) {
                const payload = {
                    review: this.item.review.review,
                    subscription: this.item.subscription,
                    observation: this.item.review.observation,
                    subspcriptionStatus: this.item.status,
                    judge: this.user.id
                };

                this.messages = [];
                this.submiting = true;

                this.submitReview(
                    payload
                )
                .then(res => {
                    if (res.success) {
                        this.reviewStatus = 'Avaliado';   
                    }
                    this.messages = res.messages;
                }).catch(() => {
                    this.messages = [{success: false, message: 'Ocorreu um erro!'}];
                }).finally(() => {
                    this.submiting = false;
                });
            }
        }
    }
}
</script>